(() => {

const tabs = document.querySelectorAll('.selected-item .head') // document.getElementsByClassName('list')[0].children
const content = document.querySelectorAll('.selected-item .body') // document.getElementsByClassName('selected-doc')[0].children

// manual slider
Array.from(tabs).map((tab, i, all) => {
  tab.addEventListener('click', () => {
    all.map(x => x.classList.remove('active'))
    Array.from(content).map(x => x.classList.remove('active'))

    tab.classList.add('active')
    content[i].classList.add('active')

    stopLoop = true
  })
})

let activeIndex = 0
let stopLoop = false
let loopId = undefined

function loopSlider() {
  if (stopLoop) return

  Array.from(tabs).map(x => x.classList.remove('active'))
  Array.from(content).map(x => x.classList.remove('active'))
  setTimeout(() => {
    tabs[activeIndex].classList.add('active')
    content[activeIndex].classList.add('active')
  })

  clearInterval(loopId)

  const slideLen = tabs.length-1

  loopId = setTimeout(() => {
    activeIndex = activeIndex >= slideLen ? 0 : activeIndex + 1
    loopSlider()
  }, 8000)
}


addEventListener('DOMContentLoaded', () => {
  const sliderBlock = document.getElementById('full-feat')

  let observer = new IntersectionObserver((entries, observer) => {
    // console.log('scroll observe', entries[0]);
    const entry = entries[0]
    if (entry.isIntersecting) {
      stopLoop = false
      loopSlider()
    } else {
      stopLoop = true
    }
  }, {
    root: null
  });

  observer.observe(sliderBlock)
})

})()